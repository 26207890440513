<template>
  <section id="banner" class="p-0">
    <div class="container overflow-hidden">
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-5 ps-0 pe-0 mx-auto mb-4"
      >
        <div class="partnership-banner-items-container">
          <div class="row align-items-center partnership-banner-item">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <p class="h2 text-main partner-corparative-text">
                <span class="text-blue">Mobins</span> haqqında
              </p>
                <p class="partner-corparative-content">
                  "Mobins" – sığorta ilə bağlı xidmətlərin onlayn satış platformasıdır.
                  Məqsədimiz - sığortanı sizə daha yaxın etmək, xidmətlərimizlə Sizə arxayınçılıq verməkdir.
                  Xidmətlərimiz vaxtlarına dəyər verən müasir, aktiv insanlara böyük fayda verəcəyinə əminik.
                </p>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <img
                src="@/assets/images/about-info.png"
                class="mx-auto partner_corp"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about-service">
    <div class="container">
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-5 pl-0 pr-0 mx-auto mb-4 mt-2"
      >
        <p class="about-text"><span class="text-blue"> Mobins </span>Sizə:</p>
        <div class="py-4 ">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Yalnız bir neçə dəqiqə ərzində tamamilə elektron sığorta polisi əldə etmək imkan verir;
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Sığorta şirkətlərinin ən optimal təkliflərini Sizə təqdim etməklə, düzgün seçim etməyinizə kömək edir;
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Sığorta şəhadətnaməsini almaqdan əvvəl və sonra sığorta ilə bağlı yaranan sualları cavablandırır, dəstəyə ehtiyac olanda kömək edir;
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Sığorta hadisələri zamanı tələb olunun sənədlərin yığılması, avtomobilə dəyən zərərin dəyərinin qiymətləndirilməsi üzrə məsləhət və sığortanın bütün müddəti ərzində dəstək.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <partners-carusel class="bg-gray" />

  <section id="contact-details" class="partnership-contact-detailes">
    <div class="container">
      <div class="row contact-details-row m-0">
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 px-0 contact-detail-item"
        >
          <a
            target="_blank"
            href="https://wa.me/+994997007711"
            class="contact-detail-item-content d-flex align-content-center flex-column text-decoration-none"
          >
            <div class="text-decoration-none d-inline-block w-100">
              <img src="@/assets/images/partner_clock.svg" />
              <p class="h6 about-item-head mt-3">7/24 müştəri xidməti</p>
              <p class="about-item-content m-0">
                Xidmət, məhsul və ya texniki problemlə bağlı çətinliyiniz varsa
                lazım olan yardımı göstərməyə hazırıq.
              </p>
            </div>
            <div class="partner-item-info">+994 99 700 77 11</div>
          </a>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 px-0 contact-detail-item"
        >
          <a
            href="mailto: info@mobins.az"
            class="contact-detail-item-content d-flex align-content-center flex-column text-decoration-none"
          >
            <div class="text-decoration-none d-inline-block w-100">
              <img src="@/assets/images/partner_mail.svg" />
              <p class="h6 about-item-head mt-3">
                E-mail üzərindən texniki dəstək
              </p>
              <p class="about-item-content m-0">
                Sualınızı və ya təklifiniz olduğu zaman bizə e-mektub göndərin,
                biz ən tez zamanda cavab verəcəyik.
              </p>
            </div>
            <div class="partner-item-info">info@mobins.az</div>
          </a>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 px-0 contact-detail-item"
        >
          <a
            target="_blank"
            href="https://goo.gl/maps/R64qBwpNTPWvL1ka6"
            class="contact-detail-item-content d-flex align-content-center flex-column text-decoration-none"
          >
            <div class="text-decoration-none d-inline-block w-100">
              <img src="@/assets/images/partner_people.svg" />
              <p class="h6 about-item-head mt-3">Ofisdə ekspert dəstəyi</p>
              <p class="about-item-content m-0">
                Ofisimiz sizə kömək üçün fəaliyyət göstərir.Sual və ya təklif
                üçün bizimlə əlaqə saxlayın.
              </p>
            </div>
            <div class="partner-item-info">
              Bakı şəh., Hacı Murad küç. 1/6, <br />
              Badamar Plaza, AZ 1075
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PartnersCarusel from "../shared/PartnersCarusel.vue";

export default {
  components: {
    PartnersCarusel,
  },
  
};
</script>